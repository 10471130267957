import { ComponentProps, FC, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { AnyAction, bindActionCreators, Dispatch } from "redux"
import { AppState } from "../../../global-state/store/root-reducers";
import { getSubjectCommunity, setActualTabManageChat } from "../../../global-state/actions/chat-actions";
import ManageCommunityPathComponent from '../../../components/community/path-community/manage-community-path-component';
import CommentCardLoading from '../../../components/community/course-community/comments/comment-card/comment-card-loading';
import ManageCommunityLoading from '../../../components/community/manage-community-loadding-component';
import { getPathContent } from '../../../global-state/actions/course-actions';
import ListCardCommentPathStudent from '../../../components/community/path-community/list-card-comment-path-student';
let outsideTab = "";
let outsidepageDiscussion = 0;
let outsidepageQuestion = 0;
let outsideTrie = "";
let outsideFiltre = "";
let outsidesearchFilter = "";
let outsideAtBottom = false;
const PathCommunityPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const [actualTab, setActualTab] = useState<string>("discussion")
    const [trie, setTrie] = useState("recent");
    const [filtre, setFiltre] = useState("all");
    const [searchFilter, setSearchFilter] = useState("");
    const [doSearch, setDoSearch] = useState(false);
    const [pageDiscussion, setPageDiscussion] = useState(0);
    const [pageQuestion, setPageQuestion] = useState(0);
    const [atBottom, setAtBottom] = useState(false);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    outsideTab = actualTab;
    outsidepageDiscussion = pageDiscussion;
    outsidepageQuestion = pageQuestion;
    outsideTrie = trie;
    outsideFiltre = filtre;
    outsidesearchFilter = searchFilter;
    outsideAtBottom = atBottom;

    let pathId = useMemo(() => props?.pathInfo?.id, [props?.pathInfo?.id])

    useEffect(() => {

        setActualTab(props?.tabChatManager);
    }, [props?.tabChatManager]);
    useEffect(() => {
        setLoading(props?.loading)
    }, [props?.loading]);
    useEffect(() => {
        setRefresh(props?.refresh)
    }, [props?.refresh]);
    useEffect(() => {
        //window.addEventListener('scroll', handleScroll, { passive: true });

        if (pathId != undefined && typeof pathId === 'number') {
            props?.getPathContent(pathId)
        }

        changeList("recent", "all", "", 0, true, false)
        setActualTab("discussion");
        return () => {
            // window.removeEventListener('scroll', handleScroll);
            props?.setActualTabManageChat("discussion");
            setTrie("recent");
            setFiltre("all");
            setDoSearch(false);
            setSearchFilter("");
            setPageDiscussion(0);
            setPageQuestion(0);
        }
    }, [])
    const handleScroll = (event: any) => {
        if (!(outsideAtBottom) && isBottom()) {
            setAtBottom(true);
            changeList(outsideTrie, outsideFiltre, outsidesearchFilter, (outsideTab === 'discussion') ? outsidepageDiscussion + 1 : outsidepageQuestion + 1, false, false);
            if (outsideTab === 'discussion') {
                setPageDiscussion(outsidepageDiscussion + 1)
            } else {
                setPageQuestion(outsidepageQuestion + 1)
            }
        }
        if (!isBottom()) {
            setAtBottom(false);
        }
    }
    const isBottom = () => {
        return ((window.innerHeight + window.scrollY) >= document.body.offsetHeight);
    }
    const getSubject = (typeInput: string) => {
        return [props?.pathInfo?.id + " PATH " + typeInput,
        props?.pathInfo?.subjectsByPath?.map((obj: any) => {
            if (obj?.course) {
                return [obj?.course?.id + " COURSE " + typeInput,
                obj?.course?.orders?.map((chap: any) => {
                    return chap.chapter.id + " CHAPTER " + typeInput
                })
                ]
            }
            if (obj?.path) {
                return [obj?.path?.id + " PATH " + typeInput,
                obj?.path?.subjectsByPath?.map((chap: any) => {
                    if (chap?.course) {
                        return [chap?.course?.id + " COURSE " + typeInput,
                        chap?.course?.orders?.map((chap1: any) => {
                            return chap1?.chapter?.id + " CHAPTER " + typeInput
                        })
                        ]
                    }
                })
                ]
            }

        })].flat(Infinity)
    }
    const changeList = (trieInput: string, filterInput: string, searchFilterInput: string, page: number, isSearch: boolean, refreshInput: boolean) => {
        if (actualTab === "discussion") {
            if (filterInput === "all") {
                let object = {
                    subject: getSubject("FORUM"),
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if (typeof object != undefined && object?.subject?.length != 0) {
                    props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            } else {
                let filtreObject = JSON.parse(filterInput);
                let subjects = filtreObject.id + " " + filtreObject.typeCategory + " FORUM"
                let object = {
                    subject: [subjects],
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if (typeof object != undefined && object?.subject?.length != 0) {
                    props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            }


        } else if (actualTab === "question") {
            if (filterInput === "all") {
                let object = {
                    subject: getSubject("QUESTION"),
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if (typeof object != undefined && object?.subject?.length != 0) {
                    props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            } else {
                let filtreObject = JSON.parse(filterInput);
                let subjects = filtreObject.id + " " + filtreObject.typeCategory + " QUESTION"
                let object = {
                    subject: [subjects],
                    trie: trieInput,
                    searchFilter: searchFilterInput,
                    page: page,
                    refresh: refreshInput,
                }
                if (typeof object != undefined && object?.subject?.length != 0) {
                    props?.getSubjectCommunity(object, props?.connectedUser?.idSchool, isSearch);
                }
            }
        }
    }
    const Search = (doSearchInput: any) => {
        switch (outsideTab) {
            case 'discussion':
                setPageDiscussion(0);
                break;
            case 'question':
                setPageQuestion(0);
                break;
        }
        setTrie(doSearchInput.trie);
        setFiltre(doSearchInput.filtre);
        setSearchFilter(doSearchInput.searchFilter)
        setDoSearch(true);
        changeList(doSearchInput.trie, doSearchInput.filtre, doSearchInput.searchFilter, 0, true, true);
    }
    return (
        <>
            {!loading && <div className="page-wrapper-height">
                <ManageCommunityPathComponent Searching={Search} />
                <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                    {refresh ?
                        <div className="col-md-10">
                            <CommentCardLoading />
                            <CommentCardLoading />
                            <CommentCardLoading />
                            <CommentCardLoading />
                        </div> :
                        <>
                            <div className="d-flex justify-content-center mx-2 px-0 mt-5 w-100">
                                <ListCardCommentPathStudent doSearch={doSearch} what={actualTab} postsList={props?.listPosts} />
                            </div>
                        </>}

                </div>
            </div>}
            {loading && <div className="page-wrapper-height">
                <ManageCommunityLoading title={props?.pathInfo?.title} />
                <div style={{ maxWidth: '1200px', margin: 'auto' }}>
                    <div className="row d-flex justify-content-center mt-5">
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                        <div className="col-md-12 p-0" >
                            <CommentCardLoading />
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    tabChatManager: state.chatReducer.tabChatManager,
    pathInfo: state.studentTraining.currentTraining,
    connectedUser: state.authReducer.connectedUser,
    listPosts: state.chatReducer.listPosts,
    loading: state.chatReducer.loadingPosts,
    refresh: state.chatReducer.refreshPosts,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators({
        getSubjectCommunity,
        setActualTabManageChat,
        getPathContent

    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PathCommunityPage);