import { ComponentProps, FC, JSXElementConstructor, Key, ReactElement, ReactNodeArray, ReactPortal, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../global-state/store/root-reducers';
import Rating from '@material-ui/lab/Rating';
import StarOutline from '@material-ui/icons/StarOutline';
import { createFollow, getApplicationFile, getRegistrationForm } from '../../global-state/actions/course-actions';
import { refreshToken } from '../../global-state/actions/auth-actions';
import { saveReturnPath, redirect, clearHistoryArray, clearSaveReturnPath } from '../../global-state/actions/breadcrumb-actions';
import { useHistory, useLocation } from 'react-router-dom';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { useTranslation } from 'react-i18next';
import inscription_file from '../../styles/imgs/inscription_file.svg';
import acces from '../../styles/imgs/acces.svg';
import { getCurrentTraining, getCurrentCourse } from '../../global-state/actions/student-training-actions'
import ReactPlayer from 'react-player';
import { displayTagSubscription } from '../../helpers/training/displayTagSubscription';
import TagDiploma from '../tag-diploma/TagDiploma';
import { calculatePathPageDuration, getDuration } from '../../base/functions/Functions';
import { openModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../modals/modal-ids';
import SkillsSectionComponent from './skills-section-component';
import { getAllFeedbacksApi } from '../../services/feedBack.service';

const DescPreviewCourse: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {

    const [rate, setRate] = useState<number>(0);
    const [clicked, setClicked] = useState<boolean>(false);
    const [type, setType] = useState<string>("course");
    const history = useHistory();
    const { t } = useTranslation();
    const [videoHeight, setVideoHeight] = useState<any>('')
    const [duration, setDuration] = useState<any>(0)
    const [resume, setResume] = useState<any>(props?.resume)
    const [objectives, setObjectives] = useState<any>(props?.objectives)
    const [prerequisite, setPrerequisite] = useState<any>(props?.prerequisite)
    const [level, setLevel] = useState<any>(props?.trainingLevel)
    const [title, setTitle] = useState<any>(props?.title)
    const [media, setMedia] = useState<any>(props?.media)
    const [video, setVideo] = useState<any>("")
    const [priceTotal, setPriceTotal] = useState<any>(null)
    const [priceAbn, setPriceAbn] = useState<any>()
    const [priceUnique, setPriceUnique] = useState<any>()
    const [priceFacilyty, setPriceFacilyty] = useState<any>([])
    const [abn, setAbn] = useState<Boolean>(false)
    const [unique, setUnique] = useState<Boolean>(false)
    const [facilyty, setFacilyty] = useState<Boolean>(false)
    const [navigateToPayment, setNavigateToPayment] = useState<Boolean>(false)
    const [per, setPer] = useState<any>()
    const [disableFollowTrainingBtn, setDisableFollowTrainingBtn] = useState<boolean>(true)
    const location = useLocation();
    const [trainingType, setTrainingType] = useState<string>("")
    const [isConnected, setIsConnected] = useState<boolean>(false)
    const [logInFromModal, setLogInFromModal] = useState<boolean>(false)
    const [screenSize, setScreenSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [trainingInfo, setTrainingInfo] = useState<any>()
    const [allFeedbacks, setAllFeedBacks] = useState<any>()


    useEffect(() => {
        if (props?.courseInfo !== undefined && props.courseForm?.type === "COURSE") {
            setTrainingInfo(props?.courseInfo)
        } else if (props.training !== undefined) {
            setTrainingInfo(props?.training)
        }
    }, [props?.courseInfo, props.training])

    const [isMobile, setIsMobile] = useState<boolean>(true)
    const [loader, setLoader] = useState<boolean>(false)

    useEffect(() => {

        if (logInFromModal === true && props?.tarification != null) {
            followTrainingCheck()
        }
    }, [logInFromModal, props?.tarification])

    useEffect(() => {
        if (props?.from?.path.includes('sign-in') || props?.from?.path.includes('sign-up')) {
            setLogInFromModal(true)
        }
    }, [props?.from])

    const handleResize = () => {
        if (window.innerWidth !== undefined && window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (props?.courseForm?.type === "PATH") {
            setDuration(calculatePathPageDuration(props?.courseForm))
        }
        else if (props?.courseForm?.type === "SESSION") {
            setDuration(calculatePathPageDuration(props?.courseForm))
        }
        else if (props?.courseForm?.type === "COURSE") {
            setDuration(props?.time)
        }
        setResume(props?.resume)
        setObjectives(props?.objectives)
        setPrerequisite(props?.prerequisite)
        setLevel(props?.trainingLevel)
        setTitle(props?.title)
        setMedia(props?.media)
        if (props?.video !== undefined && props?.video?.length !== 0) {
            setVideo(props?.video)
        }
    }, [props])

    useEffect(() => {
        if (props?.video && (props?.video.includes('dai.ly') || props?.video.includes('dailymotion.com'))) {
            setVideoHeight('500px')
        }
        else {
            setVideoHeight('100%')
        }
    }, [props?.video])

    useEffect(() => {
        if (props?.type) {
            setType(props?.type);
        }
    }, [props?.type]);

    const getAllFeedbacks = async (idSubject: number, page: number, action: string) => {
        const result = await getAllFeedbacksApi(idSubject, page, action)
        setAllFeedBacks(result)
    }

    const getFeedbacksData = async () => {
        if (props?.courseForm?.id) {
            await getAllFeedbacks(props?.courseForm?.id, 0, 'refresh');
        }
    }

    useEffect(() => {
        getFeedbacksData();
        if (allFeedbacks && allFeedbacks.feedbacks.length > 0) {
            setRate(allFeedbacks.feedbacks.map((obj: any) => { return obj.rate }).reduce((a: any, b: any) => a + b, 0) / allFeedbacks.feedbacks.length);
        }
    }, [props?.courseForm]);

    useEffect(() => {
        if (!allFeedbacks) return
        setRate(allFeedbacks.feedbacks.map((obj: any) => { return obj.rate }).reduce((a: any, b: any) => a + b, 0) / allFeedbacks.feedbacks.length);
    }, [allFeedbacks]);

    useEffect(() => {
        if (props?.followByTraining !== undefined && props?.followByTraining?.length > 0) {
            setDisableFollowTrainingBtn(true)
        }
        if (screenSize?.width !== undefined && screenSize?.width < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [screenSize])

    const displayPrice = () => {
        props?.trainingOffer?.pricings?.map((el: any) => {
            if (el.period === "ONESHOT") setPriceTotal({ price: el.price, currency: el.currency })
        })
    }

    const getType = () => {
        switch (props.type) {
            case "COURSE":
                return "Cours"
            case "PATH":
                return "Parcours "
            case "SESSION":
                return "Session "
            default:
                break;
        }
    }

    const getPeriod = (period: any) => {
        switch (period) {
            case "MONTH":
                return "Mois"
            case "WEEK":
                return "Semaine "
            case "QUARTER":
                return "Trimestre "
            case "SEMESTER":
                return "Semestre "
            default:
                break;
        }
    }

    const getTypeFollow = () => {
        switch (props.type) {
            case "COURSE":
                return "ce Cours"
            case "PATH":
                return "ce Parcours "
            case "SESSION":
                return "cette Session "
            default:
                break;
        }
    }

    const getTypeIcon: any = () => {
        switch (props?.type) {
            case "COURSE": return <>
                <div className="d-flex align-items-center ">
                    <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0)">
                            <path d="M14.2222 0.500061H1.77778C0.8 0.500061 0 1.30006 0 2.27784V14.7223C0 15.7001 0.8 16.5001 1.77778 16.5001H14.2222C15.2 16.5001 16 15.7001 16 14.7223V2.27784C16 1.30006 15.2 0.500061 14.2222 0.500061ZM14.2222 14.7223H1.77778V2.27784H14.2222V14.7223Z" fill="#5693EE" />
                        </g>
                        <line x1="5.38184" y1="10.2764" x2="10.6187" y2="10.2764" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <line x1="10.6182" y1="6.72375" x2="5.38132" y2="6.72375" stroke="#5693EE" strokeWidth="1.5" strokeLinecap="round" />
                        <defs>
                            <clipPath id="clip0">
                                <rect width="16" height="16" fill="white" transform="translate(0 0.500061)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>

            </>
            case "PATH": return <>
                <div className="d-flex align-items-center ">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.45455 2.90909H0V14.5455C0 15.3455 0.654545 16 1.45455 16H13.0909V14.5455H1.45455V2.90909ZM14.5455 0H4.36364C3.56364 0 2.90909 0.654545 2.90909 1.45455V11.6364C2.90909 12.4364 3.56364 13.0909
                     4.36364 13.0909H14.5455C15.3455 13.0909 16 12.4364 16 11.6364V1.45455C16 0.654545 15.3455 0 14.5455 0ZM14.5455 11.6364H4.36364V1.45455H14.5455V11.6364Z" fill="#FA83EE" />
                    </svg>
                </div>

            </>
            case "SESSION": return <>
                <div className="d-flex align-items-center ">
                    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)"><path d="M1.45455 5.22727H0V16.1364C0 16.8864 0.654545 17.5 1.45455 17.5H13.0909V16.1364H1.45455V5.22727ZM14.5455 2.5H4.36364C3.56364 2.5 2.90909 3.11364 2.90909 3.86364V13.4091C2.90909 14.1591 3.56364 14.7727 4.36364 14.7727H14.5455C15.3455 14.7727 16 14.1591 16 13.4091V3.86364C16 3.11364 15.3455 2.5 14.5455 2.5ZM14.5455 13.4091H4.36364V3.86364H14.5455V13.4091Z" fill="#8A57CC"></path></g><line x1="4" y1="7.35" x2="15" y2="7.35" stroke="#8A57CC" strokeWidth="1.3"></line><line x1="13.35" y1="3" x2="13.35" stroke="#8A57CC" strokeWidth="1.3"></line><line x1="5.35" y1="3" x2="5.35" stroke="#8A57CC" strokeWidth="1.3"></line><defs><clipPath id="clip0"><rect width="16" height="16" fill="white" transform="translate(0 1.5)"></rect></clipPath></defs></svg>
                </div>

            </>
            default: return <></>
        }
    }

    const getAccess = () => {
        if (props?.courseForm !== "DRAFT" || props?.tarification === undefined) {
            return "Accès "
        } else {
            return props?.tarification?.rules[1]?.value === "-1" ? "Accès illimité" : `Accès ${props?.tarification?.rules[1]?.value} mois`
        }

    }

    const followTraining = async (trainingType: string) => {

        if (props?.connectedUser && props?.connectedUser?.role === "role_student") {
            if ((props?.courseInfo?.idRegistrationForm === 0 || props?.courseInfo?.idRegistrationForm === null || props?.courseInfo?.idRegistrationForm === undefined) && (props?.tarification?.pricings[0]?.price === 0 || props?.tarification === undefined)) {
                let contractToCreate = {
                    contractIdentifier: {
                        contractId: null,
                        contractReference: null
                    },
                    contractDetails: {
                        offerId: props?.tarification?.pricings[0]?.offer_id,
                        contractDuration: {
                            startAt: new Date(Date.now()),
                            endAt: null
                        },
                        pricingId: props?.tarification?.pricings[0]?.id,
                        paymentMethod: "CARD",
                        paymentEngine: "STRIPE"
                    },
                    contractRoles: [
                        {
                            personId: props?.connectedUser?.idPerson,
                            roleType: "STUDENT"
                        }
                    ],
                    status: [
                        {
                            statusType: "ONGOING"
                        }
                    ]
                }

                let follow = {
                    studentId: props?.connectedUser?.idPerson,
                    trainingId: props?.courseForm.id,
                    trainingType: props?.courseForm.type,
                    entityId: props?.courseForm.entityId,
                    entityType: props?.courseForm.entityType,
                    statusList: [{
                        status: "INSCRIBED"
                    }],
                }

                await props?.createFollow(follow, contractToCreate);
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson);
                setClicked(true);
            } else if ((trainingInfo?.idRegistrationForm === 0 || trainingInfo?.idRegistrationForm === null || trainingInfo?.idRegistrationForm === undefined) && (trainingInfo?.price?.price !== 0)) {
                props?.clearHistoryArray()
                props?.redirect(trainingInfo?.title, "")
                props?.redirect("Paiement", "")
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${props?.envUrl}/courses/payement/${trainingType}/${props?.courseInfo?.id}`)
                });
            }
            else if ((trainingInfo?.idRegistrationForm !== 0 && trainingInfo?.idRegistrationForm !== null && trainingInfo?.idRegistrationForm !== undefined) ||
                ((trainingInfo?.idRegistrationForm !== 0 && trainingInfo?.idRegistrationForm !== null && trainingInfo?.idRegistrationForm !== undefined) && (trainingInfo?.price?.price !== 0))) {
                await props?.getApplicationFile(props?.connectedUser?.idPerson, trainingInfo?.idRegistrationForm);
                await props?.getRegistrationForm(trainingInfo?.idRegistrationForm);
                props?.redirect(t('Subscription'), `${props?.envUrl}/catalog/inscription/${trainingInfo?.type?.toLowerCase()}/${trainingInfo?.idRegistrationForm}`)
                await props?.refreshToken(props?.connectedUser?.idSchool, props?.connectedUser?.idPerson).then(async (resp: any) => {
                    history.push(`${props?.envUrl}/catalog/inscription/${props?.courseInfo?.type?.toLowerCase()}/${props?.courseInfo?.idRegistrationForm}`);
                });

            }

        }
    }

    const followTrainingCheck = async () => {
        setDisableFollowTrainingBtn(true)
        if (!!props?.followByTraining && props?.followByTraining?.length > 0) {
            history.push(`${props?.envUrl}${(props?.courseInfo?.type === 'COURSE') ? '/passCourse/course' : (props?.courseInfo?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.followByTraining[0]?.id}`);
        } else if (isMobile === false && isConnected === false) {
            await setLogInFromModal(true)
            await props?.openModal({ idModal: ModalIDS.SIGN_UP_MODAL, size: "sm", data: {} });
            await setDisableFollowTrainingBtn(false)

        } else if (isMobile === false && isConnected === true) {
            await props?.saveReturnPath({ path: `${props?.envUrl}/catalog/preview/course/${props?.courseInfo?.id}`, tabPage: '' });
            await followTraining(props?.courseInfo.type)

        } else if (isMobile === true && isConnected === false) {
            if (props.courseForm?.type === "COURSE") {
                await props?.saveReturnPath({ path: `/catalog/preview/course/${props?.courseForm?.id}`, tabPage: '' });
            }
            if (props.courseForm?.type === "PATH") {
                await props?.saveReturnPath({ path: `/catalog/preview/path/${props?.courseForm?.id}`, tabPage: '' });
            }
            if (props.courseForm?.type === "SESSION") {
                await props?.saveReturnPath({ path: `/catalog/preview/session/${props?.courseForm?.id}`, tabPage: '' });
            }
            history.push(`${props?.envUrl}/auth/sign-up`);

        } else if (isMobile === true && isConnected === true) {
            await followTraining(props?.courseForm.type)
        }

    }

    const redirectAfterModalSignUp = async () => {
        if (props?.connectedUser && isConnected === true && isMobile === false && logInFromModal === true) {
            await followTraining(trainingType)
        }

        if (props?.connectedUser && isConnected === true && isMobile === true && logInFromModal === true) {
            await followTraining(props?.courseForm?.type)
        }
    }

    useEffect(() => {
        redirectAfterModalSignUp()
    }, [props?.connectedUser, isConnected, isMobile])

    useEffect(() => {
        setClicked(false);
        getFeedbacksData();
        displayPrice()
        setDisableFollowTrainingBtn(true)
    }, []);

    useEffect(() => {
        if (!!props?.connectedUser && props?.connectedUser?.role?.includes("role_student")) {
            setIsConnected(true)
        }
    }, [props?.connectedUser])

    useEffect(() => {
        setPriceFacilyty([])
        setFacilyty(false)
        setAbn(false)
        setUnique(false)
        props?.tarification?.pricings?.map((pricing: any) => {
            if (pricing.period === "ONESHOT" && pricing.nbPeriod === null && pricing.price !== 0) {
                setPriceUnique(pricing.price);
                setUnique(true)
                setAbn(false)
            }
            if (pricing.period === "MONTH" && pricing.nbPeriod != null) {
                const newObject = { price: pricing.price, period: pricing.nbPeriod };
                setPriceFacilyty((prevState: any) => [...prevState, newObject]);
                setFacilyty(true)
                setAbn(false)
            }
            if (pricing.price === 0 && pricing.period === "ONESHOT" && pricing.nbPeriod === null) {
                setFacilyty(false)
                setAbn(false)
                setUnique(false)
            }
            if (["MONTH", "WEEK", "QUARTER", "SEMESTER"].includes(pricing.period) && pricing.nbPeriod == null) {
                setPriceAbn(pricing.price)
                setPer(getPeriod(pricing.period))
                setAbn(true)
                setFacilyty(false)
                setUnique(false)
            }
        });

    }, [props.tarification]);

    useEffect(() => {
        if ((props?.follow && typeof props?.follow[0]?.id !== 'undefined' && typeof props?.follow[0]?.id === 'number' && clicked)) {
            if (props.courseForm?.type !== 'COURSE') {
                props?.getCurrentTraining(props?.follow[0]?.id);
            } else {
                if (!!props?.follow[0]?.id) {
                    props?.getCurrentCourse(props?.follow[0]?.id);
                }
            }
            history.push(`${props?.envUrl}${(props.courseForm?.type === 'COURSE') ? '/passCourse/course' : (props.courseForm?.type === 'PATH') ? '/passPath/progressPath' : '/passSession/progressSession'}/${props?.follow[0]?.id}`);
        }
    }, [props?.follow, clicked]);

    useEffect(() => {
        if (props?.courseForm?.type === "PATH") {
            setDuration(calculatePathPageDuration(props?.courseForm))
        }
        else if (props?.courseForm?.type === "SESSION") {
            setDuration(calculatePathPageDuration(props?.courseForm))
        }
        else if (props?.courseForm?.type === "COURSE") {
            setDuration(props?.time)
        }
        setResume(props?.resume)
        setObjectives(props?.objectives)
        setPrerequisite(props?.prerequisite)
        setLevel(props?.trainingLevel)
        setTitle(props?.title)
        setMedia(props?.media)
        if (props?.video !== undefined && props?.video?.length !== 0) {
            setVideo(props?.video)
        }
    }, [props])

    useEffect(() => {
        if (props?.video && (props?.video.includes('dai.ly') || props?.video.includes('dailymotion.com'))) {
            setVideoHeight('500px')
        }
        else {
            setVideoHeight('100%')
        }
    }, [props?.video])

    useEffect(() => {
        if (props?.type) {
            setType(props?.type);
        }
    }, [props?.type]);

    useEffect(() => {
        getFeedbacksData();
        if (allFeedbacks && allFeedbacks.feedbacks.length > 0) {
            setRate(allFeedbacks.feedbacks.map((obj: any) => { return obj.rate }).reduce((a: any, b: any) => a + b, 0) / allFeedbacks.feedbacks.length);
        }
        setTrainingType(props.courseForm?.type);
    }, [props?.courseForm]);

    useEffect(() => {
        if (!allFeedbacks) return
        setRate(allFeedbacks.feedbacks.map((obj: any) => { return obj.rate }).reduce((a: any, b: any) => a + b, 0) / allFeedbacks.feedbacks.length);
    }, [allFeedbacks]);

    useEffect(() => {
        if (props?.followByTraining !== undefined && props?.followByTraining?.length > 0) {
            setDisableFollowTrainingBtn(true)
        } else {
            setDisableFollowTrainingBtn(false)
        }
    }, [props?.followByTraining])

    return (
        <div className='container-desc-preview-course mx-auto' style={{ position: 'relative' }}>
            <div className={`relations-theory ${location.pathname.includes('/catalog/preview') ? 'padding-description mt-40' : 'padding-description'}`} style={{ backgroundColor: props?.schoolInfo?.color ? props?.schoolInfo?.color : "#212529", color: '#fff' }}>

                {(video !== "" && video?.length !== 0 && !props?.transcoding) ?
                    <div className='mx-auto img-more-info custom-video-youtube d-xl-none mb-4 d-flex justify-content-center max-width course-img-padding-top'>
                        <ReactPlayer
                            controls={true}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        responsive: true,
                                    },
                                },
                                youtube: {
                                    embedOptions: {
                                        height: '500px',
                                    }
                                },
                                dailymotion: {}
                            }}
                            width='100%'
                            height={videoHeight}
                            stopOnUnmount={false}
                            url={video} />
                    </div> :
                    <div className='img-more-info d-xl-none mb-4 d-flex justify-content-center course-img-padding-top'>
                        <img src={media} alt="" className='ratio ratio-1x1 max-width' />
                    </div>
                }

                <div className='mx-auto d-none d-xl-block' style={{ maxWidth: "1200px" }}>
                    <div className="title H2-Headline" style={{ color: '#fff', maxWidth: '687px' }}>{title}</div>
                    <div className="description" style={{ maxWidth: '687px', margin: '15px 0' }} dangerouslySetInnerHTML={{ __html: resume }} />
                    {(props.courseForm && props.courseForm.theme && props.courseForm.theme.length > 0 && props.courseForm.theme[0] !== '') ? <SkillsSectionComponent type={"theme"} skills={props.courseForm.theme} /> : props.courseInfo && props.courseInfo.theme && props.courseInfo.theme.length > 0 && props.courseInfo.theme[0] !== '' ? <SkillsSectionComponent type={"theme"} skills={props.courseInfo.theme} /> : props.training && props.training.theme && props.training.theme.length > 0 && props.training.theme[0] !== '' && <SkillsSectionComponent type={"theme"} skills={props.training.theme} />}

                    <div className="d-flex align-items-center justify-content-start">
                        <div className="d-flex align-items-center me-3">
                            <span className="material-icons-outlined neutral-3 me-1">signal_cellular_alt</span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{t(level)}</span>
                        </div>
                        <div className="d-flex align-items-center me-3">
                            <span className="material-icons-outlined neutral-3 me-1">timer</span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{t('durationChapter')} {(props?.time === '0 MN') ? '...' : props?.courseForm?.type === "COURSE" ? duration : getDuration(duration)}</span>
                            <span></span>
                        </div>

                        {props?.courseInfo?.idRegistrationForm != null && <div className="d-flex align-items-center me-3">
                            <span className=" me-1"><img src={inscription_file} alt="" /></span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{t('withInscription')}</span>
                            <span></span>
                        </div>}
                        <div className="d-flex align-items-center me-3">
                            <span className="neutral-3 me-1"><img src={acces} alt="" /></span>
                            <span className="body-md bold black-800" style={{ color: '#fff', marginTop: '5px' }}>{getAccess()}</span>
                            <span></span>
                        </div>
                    </div>
                </div>

                <div className='mx-auto d-block d-xl-none max-width px-3 px-sm-0'>
                    <div className={isMobile ? "H2-Headline mb-0" : "H3-Headline "} style={{ color: '#fff' }}>{title}</div>
                    <div className="description max-width mt-2" dangerouslySetInnerHTML={{ __html: resume }} />
                    <div className="d-sm-flex align-items-center justify-content-between d-xl-none mb-3">
                        <div>
                            <div className="d-flex align-items-center me-3 mt-3 mt-xl-0">
                                <span className="material-icons-outlined neutral-3 me-1 mr-1 mr-lg-0" >signal_cellular_alt</span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-lg-0" style={{ color: '#fff' }}>{t(level)}</span>
                            </div>
                            <div className="d-flex align-items-center me-3 mt-2 mt-xl-0" >
                                <span className="material-icons-outlined neutral-3 me-1">timer</span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-lg-0" style={{ color: '#fff' }}>{t('durationChapter')} {(props?.time === '0 MN') ? '...' : props?.courseForm?.type === "COURSE" ? duration : getDuration(duration)}</span>
                                <span></span>
                            </div>
                        </div>

                        <div>
                            {props?.courseInfo?.idRegistrationForm != null && <div className="d-flex align-items-center me-3 mt-2 mt-xl-0">
                                <span className=" me-1"><img src={inscription_file} alt="" /></span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-lg-0" style={{ color: '#fff' }}>{t('withInscription')}</span>
                                <span></span>
                            </div>}
                            <div className="d-flex align-items-center mt-3 mt-md-0">
                                <span className="neutral-3 me-1"><img src={acces} alt="" /></span>
                                <span className="body-md bold black-800 mt-1 ml-1 ml-md-0" style={{ color: '#fff' }}>{getAccess()}</span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex align-items-center d-xl-none mt-2 mt-xl-0'>
                            <div className='flex flex-row ' style={{ marginRight: '5px' }}>
                                {getTypeIcon()}
                            </div>
                            <div className='flex flex-row body-sm bold ml-1 ml-lg-0'>{getType()} donné par {props?.courseForm?.professor?.firstName && props?.courseForm?.professor?.lastName && props?.courseForm?.professor?.firstName + " " + props?.courseForm?.professor?.lastName}</div>
                        </div>
                        {props.courseForm?.isDiplomat && <div className="d-xl-none" ><TagDiploma type={props.courseForm?.type} /></div>}
                    </div>

                    <div className='rating d-xl-none mt-2' style={{ display: 'flex', height: '30px', alignItems: 'center' }}>
                        <Rating
                            readOnly
                            precision={0.1}
                            name="customized-empty"
                            value={rate}
                            emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                            style={{ color: "#F5B95F", height: '100%' }} />
                        <button style={{ backgroundColor: "transparent", border: "0", borderBottom: "1px solid white", fontWeight: "500", color: "white" }} onClick={() => props?.avisClicked()}>Avis ({allFeedbacks ? allFeedbacks?.totalItems : 0})</button>
                    </div>
                    {props?.courseForm?.status !== "DRAFT" && <div className='d-flex justify-content-between mt-4'>
                        <div>
                            <div className="" style={{ marginBottom: 12 }}>
                                {displayTagSubscription(props.tarification).map((el: any) =>
                                    <span className={`badge-catalog-payment ${el.className}`}>{el.title}</span>)}
                            </div>
                            {unique && <div className='H3-Headline'>{priceUnique}<span className='neutral-3'>€</span></div>}
                        </div>
                        {facilyty && <><hr /><div className='d-flex flex-column  mb-2'>

                            {unique && <div className='flex body-sm bold mb-2'>Ou payez en plusieurs fois</div>}
                            <div className='d-flex flex-row'>
                                {priceFacilyty.map((item: { price: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; period: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
                                    <div className='flex flex-row body-md bold me-3'>{item.price}<span className='neutral-3'>€ X{item.period}</span></div>
                                ))}
                            </div>
                        </div>
                        </>
                        }

                        {abn && <div className='H3-Headline'>{priceAbn}<span className='neutral-3'>€ /{per}</span></div>}
                    </div>}
                    <div className='' style={{ textAlign: 'center', paddingTop: "24px" }}>
                        {!loader ?
                            <button
                                className="btn-Primary large"
                                style={{ width: '100%' }}
                                //onClick={() => followTraining(props.courseForm?.type)}
                                onClick={() => followTrainingCheck()}
                                disabled={(props?.connectedUser && (props?.connectedUser?.role === "role_director" || props?.connectedUser?.role === "role_prof")) || disableFollowTrainingBtn}
                            > suivre {props?.followAction}</button>
                            :
                            <button className="btn-Primary loaddata large" style={{ width: "100%" }}>
                                <div className="threedots-large-Animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </div>
            {objectives && <div className="objective padding-description px-3 px-xl-0 mt-4 mt-xl-0" style={{ backgroundColor: '#fff' }}>
                <div className='mx-auto max-width'>
                    <div className='width'>
                        <div className="title H3-Headline">{t('objectives')}</div>
                        <div className="desc" style={{ maxWidth: '687px', margin: '15px 0', wordWrap: "break-word" }}>
                            {objectives}
                        </div>
                    </div>
                </div>
            </div>}
            {prerequisite && <div className='prerequisites padding-description px-3 px-xl-0 mt-4 mt-xl-0' style={{ backgroundColor: '#FBFBFD' }}>
                <div className='mx-auto max-width'>
                    <div className="title H3-Headline">Prérequis pour cette formation</div>
                    <div className="des" style={{ maxWidth: '687px', margin: '15px 0', wordWrap: "break-word" }}>
                        {prerequisite}
                    </div>
                </div>
            </div>}
            <div
                className="more-info d-none d-xl-block"
                style={{ position: 'absolute', top: '50px', right: '86px', backgroundColor: '#fff', boxShadow: '0px 8px 12px rgba(159, 172, 173, 0.25)' }}>
                {(video !== "" && video?.length !== 0 && !props?.transcoding) ?
                    <div className='img-more-info custom-video-youtube' style={{ width: "370px", height: "254px" }}>
                        <ReactPlayer
                            controls={true}
                            config={{
                                vimeo: {
                                    playerOptions: {
                                        responsive: true,
                                    },
                                },
                                youtube: {
                                    embedOptions: {
                                        height: '500px',
                                    }
                                },
                                dailymotion: {}
                            }}
                            width='100%'
                            height={videoHeight}
                            stopOnUnmount={false}
                            url={video} />
                    </div> :
                    <div className='img-more-info'>
                        <img src={media} alt="" style={{ width: "370px", height: "254px" }} />
                    </div>
                }
                <div className="content-more-info" style={{ position: 'relative', padding: '20px 25px 0px 25px' }}>
                    <div style={{ marginBottom: 12 }}>{displayTagSubscription(props?.tarification).map((el: any) => <span className={`badge-catalog-payment ${el.className}`}>{el.title}</span>)}</div>
                    {abn && <div className='H3-Headline'>{priceAbn}<span className='neutral-3'>€ /{per}</span></div>}
                    {unique && <div className='H3-Headline'>{priceUnique}<span className='neutral-3'>€</span></div>}
                    {facilyty && <><hr /><div className='d-flex flex-column  mb-2'>
                        {unique && <div className='flex  body-sm bold mb-2'>Ou payez en plusieurs fois</div>}
                        <div className='d-flex flex-row'>
                            {priceFacilyty.map((item: { price: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; period: string | number | boolean | {} | ReactElement<any, string | JSXElementConstructor<any>> | ReactNodeArray | ReactPortal | null | undefined; }, index: Key | null | undefined) => (
                                <div className='flex flex-row body-md bold me-3'>{item.price}<span className='neutral-3'>€ X{item.period}</span></div>
                            ))}
                        </div>
                    </div></>}

                    <hr />
                    <div className='d-flex  align-items-center mb-2' >
                        <div className='flex flex-row ' style={{ marginRight: '5px' }}>
                            {getTypeIcon()}
                        </div>
                        <div className='flex flex-row body-sm bold' >{getType()} donné par {props.courseForm?.professor?.firstName && props.courseForm?.professor?.lastName && props.courseForm?.professor?.firstName + " " + props.courseForm?.professor?.lastName}</div>
                    </div>
                    {props.courseForm?.isDiplomat && <TagDiploma type={props.courseForm?.type} />}
                    <div className='rating' style={{ display: 'flex', height: '30px', alignItems: 'center' }}>
                        <Rating
                            readOnly
                            precision={0.1}
                            name="customized-empty"
                            value={rate}
                            emptyIcon={<StarOutline fontSize="inherit" htmlColor="#DADBDF" />}
                            style={{ color: "#F5B95F", height: '100%' }} />
                        <button className="btn-text outlined " onClick={() => props?.avisClicked()}>Avis ({allFeedbacks ? allFeedbacks?.totalItems : 0})</button>
                    </div>
                    <hr />
                    <div className='' style={{ textAlign: 'center', paddingBottom: "24px" }}>
                        {!loader ? <button
                            className="btn-Primary large"
                            style={{ width: '90%' }}
                            //onClick={() => followTraining(props.courseForm?.type)}
                            onClick={() => followTrainingCheck()}
                            disabled={(props?.connectedUser && (props?.connectedUser?.role === "role_director" || props?.connectedUser?.role === "role_prof")) || disableFollowTrainingBtn}
                        > suivre {getTypeFollow()}</button>
                            :
                            <button className="btn-Primary loaddata large" style={{ width: "90%" }}>
                                <div className="threedots-large-Animation">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div >

    )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    createFollow,
    refreshToken,
    getApplicationFile,
    saveReturnPath,
    getCurrentTraining,
    getCurrentCourse,
    getRegistrationForm,
    redirect,
    clearHistoryArray,
    openModal,
    clearSaveReturnPath
}, dispatch);
const mapStateToProps = (state: AppState, ownProps: any) => ({
    time: ownProps?.time,
    price: ownProps?.price,
    avisClicked: ownProps?.avisClicked,
    courseForm: ownProps?.courseForm,
    follow: state.courseReducer.follow,
    connectedUser: state.authReducer.connectedUser,
    type: ownProps?.type,
    courseInfo: state.courseReducer.courseInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    tarification: state.courseReducer.courseTarificationForm,
    followByTraining: state.courseReducer.followsByTraining,
    transcoding: state.uploadReducer.transcoding,
    redirectPopup: state.historyReducer.redirectPopup,
    from: state.historyReducer.returnPathPlateform,
    training: state.courseReducer.training,
    envUrl: state.historyReducer.envUrl
});

export default connect(mapStateToProps, mapDispatchToProps)(DescPreviewCourse);