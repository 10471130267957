import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { load, save } from "redux-localstorage-simple";
import thunk from "redux-thunk";
import rootReducer from "./global-state/store/root-reducers";
import { Provider } from "react-redux";
import AppContainer from "./AppContainer/AppContainer";
import ReactGA from "react-ga";
import { useEffect} from "react";
import "./translations/i18n";
import 'moment/min/locales';
import TagManager from 'react-gtm-module'
import { UserGuideProvider } from "./context/user-guide-context";

const middleware = [thunk];

const tagManagerArgs = {
    gtmId: 'GTM-PKXDMDQ'
  }
export const store = createStore(rootReducer, load(), composeWithDevTools(applyMiddleware(...middleware, save())));
const TRACKING_ID = "G-TZ2BMZ3FBS"; // YOUR_OWN_TRACKING_ID

if(typeof window !== 'undefined' && !window?.location?.href.includes("localhost") && process.env.REACT_APP_RUN_DATAGOG_RUM ==='true'){
ReactGA.initialize(TRACKING_ID);
TagManager.initialize(tagManagerArgs)
}


function App(props) {
  const currentLanguage = localStorage.getItem("language");
  useEffect(() => {
    if (currentLanguage === "ar") {
      document.body.dir = "rtl";
    } else {
      document.body.dir = "ltr";
    }
  }, [currentLanguage]);


  return (
    <>
      <Provider store={store}>
        <UserGuideProvider>
          <AppContainer {...props} />
        </UserGuideProvider>
      </Provider>
    </>
  );
}
export default App;
