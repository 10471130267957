import axios from 'axios';
import qs from 'querystring';
import jwt from 'jwt-decode';
import historyBrowser from './history-config';
import rootReducer, { appReducer } from '../global-state/store/root-reducers';
import { EReduxActionTypes } from '../global-state/actions/user-auth-actions';
import { checkIsAppOrSubDoamin, getCodeFromUrl } from './domainCheck';
import { displaySnackBar } from "../global-state/actions/modal-actions"
import { store } from '../App'

const arrayError = ['VI', 'CB', 'Le compte utilisateur est d&eacute;sactiv&eacute;', 'Bad credentials']
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(async (config) => {
  let code = '';
  if (checkIsAppOrSubDoamin()) {
    code = `/${getCodeFromUrl()}`;
  }
  let token = localStorage.getItem('access_token');
  let tickets = localStorage.getItem('access_tickets')
  let limitations = localStorage.getItem('access_limitation')
  let baseUrl = process.env.REACT_APP_BASE_URL1;
  let refresh_token = JSON.parse(JSON.stringify(localStorage.getItem("refresh_token")));
  let header = {};
  if (!window.location.href.includes('localhost')) {
    header = {
      'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
      'Content-Type': 'application/x-www-form-urlencoded',
      "apiKey": `${process.env.REACT_APP_API_KEY_KONG}`
    }
  } else {
    header = {
      'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
      'Content-Type': 'application/x-www-form-urlencoded',
      "apiKey": `${process.env.REACT_APP_API_KEY_KONG}`
    }
  }
  if (token && token !== null) {
    const user = jwt(token.replace(/\"/g, ""));
    const redux = JSON.parse(localStorage.getItem("redux_localstorage_simple"));
    if (user.exp < (Date.now() / 1000)) {
      token = await axios.request({
        url: "/oauth/token",
        method: "post",
        baseURL: baseUrl,
        headers: header,
        data: qs.stringify({
          "grant_type": "refresh_token",
          "refresh_token": refresh_token.replace(/\"/g, ""),
          "actualRole": redux?.authReducer?.connectedUser?.role,
          "schoolId": redux?.authReducer?.connectedUser?.idSchool,
        })
      }).then((result) => {
        localStorage.setItem("is_logged", JSON.stringify(true))
        localStorage.setItem("access_token", JSON.stringify(result.data.access_token))
        localStorage.setItem("refresh_token", JSON.stringify(result.data.refresh_token))
        localStorage.setItem("expires_in", JSON.stringify(result.data.expires_in))
        localStorage.setItem("lastConnexion", JSON.stringify(result.data.lastConnexion))
        return JSON.stringify(result.data.access_token);
      }).catch((er) => {
        rootReducer(appReducer, EReduxActionTypes.LOGOUT_USER)
        const offer_token = localStorage.getItem("access_limitation");
        localStorage.clear();
        localStorage.setItem("access_limitation", offer_token);
        if (!window.location.href.includes('/auth/login') && !window.location.href.includes('/catalog')) {
          historyBrowser.push(`${code}/auth/login`);
        }
      })
      config.headers['Authorization'] = 'Bearer ' + token?.replace(/\"/g, "");
      if (config.url.includes("chat")) {
        config.data['Access-Tickets'] = tickets?.replace(/\"/g, "");
      }
      config.headers['Access-Limitations'] = limitations;
    } else {
      config.headers['Authorization'] = 'Bearer ' + token.replace(/\"/g, "");
    }
  }
  if (token && token !== null && tickets && tickets !== null) {
    if (config.url.includes("chat")) {
      config.data['Access-Tickets'] = tickets?.replace(/\"/g, "");
    }
  }
  if (limitations && limitations !== null) {
    config.headers['Access-Limitations'] = limitations;
  }
  // if (!window.location.href.includes('localhost')) {
  config.headers['apiKey'] = `${process.env.REACT_APP_API_KEY_KONG}`;
  // }
  return config;
});

// if (!window.location.href.includes('localhost')) {
axiosInstance.defaults.headers.common['apiKey'] = `${process.env.REACT_APP_API_KEY_KONG}`;
// }
axiosInstance.interceptors.response.use((response) => response, (error) => {
  let code = '';
  if (checkIsAppOrSubDoamin()) {
    code = `/${getCodeFromUrl()}`;
  }
  const redux = JSON.parse(localStorage.getItem("redux_localstorage_simple"));
  if (!window.location.href.includes('/auth/login') && !window.location.href.includes('/catalog')) {
    if((window.location.href.includes('/passCourse') || window.location.href.includes('/passSession') || window.location.href.includes('/passPath')) && error.response && (error.response.status === 502 || error.response.status === 503)  ) {
      store.dispatch(displaySnackBar(true, error))
      throw error;
    } else if (error.response && (error.response.status >= 500 || error.response.status === 400) && !arrayError.includes(error.response.data.error_description)) {
      store.dispatch(displaySnackBar(true, error))
    } else if (error.response && error.response.status === 404 && !arrayError.includes(error.response.data.error_description)) {
      historyBrowser.push(`${code}/404`);
    } else if (error.response && error.response.status === 403) {
      store.dispatch(displaySnackBar(true, error))
    } else if (error.response && error.response.status === 401 && (error.response.data.error_description.includes("Access token expired") || error.response.data === 'Unauthorized')) {
      let baseUrl = process.env.REACT_APP_BASE_URL1;
      let refresh_token = JSON.parse(JSON.stringify(localStorage.getItem("refresh_token")));
      let token = localStorage.getItem('access_token');
      let header = {};
      if (!window.location.href.includes('localhost')) {
        header = {
          'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
          'Content-Type': 'application/x-www-form-urlencoded',
          "apiKey": `${process.env.REACT_APP_API_KEY_KONG}`
        }
      } else {
        header = {
          'Authorization': 'Basic VVNFUl9DTElFTlRfQVBQOnBhc3N3b3Jk',
          'Content-Type': 'application/x-www-form-urlencoded',
          "apiKey": `${process.env.REACT_APP_API_KEY_KONG}`
        }
      }
      axios.request({
        url: "/oauth/token",
        method: "post",
        baseURL: baseUrl,
        headers: header,
        data: qs.stringify({
          "grant_type": "refresh_token",
          "refresh_token": refresh_token.replace(/\"/g, ""),
          "actualRole": redux.authReducer.connectedUser.role,
          "schoolId": redux.authReducer.connectedUser.idSchool,
        })
      }).then((result) => {
        token = JSON.stringify(result.data.access_token)
        localStorage.setItem("is_logged", JSON.stringify(true))
        localStorage.setItem("access_token", JSON.stringify(result.data.access_token))
        localStorage.setItem("refresh_token", JSON.stringify(result.data.refresh_token))
        localStorage.setItem("expires_in", JSON.stringify(result.data.expires_in))
        localStorage.setItem("lastConnexion", JSON.stringify(result.data.lastConnexion))
        window.location.reload();
      }).catch((er) => {
        rootReducer(appReducer, EReduxActionTypes.LOGOUT_USER)
        const offer_token = localStorage.getItem("access_limitation");
        localStorage.clear();
        localStorage.setItem("access_limitation", offer_token);
        historyBrowser.push(`${code}/auth/login`);
      })
    } else if (error.response && error.response.status === 401) {
      rootReducer(appReducer, EReduxActionTypes.LOGOUT_USER)
      const offer_token = localStorage.getItem("access_limitation");
      localStorage.clear();
      localStorage.setItem("access_limitation", offer_token);
      historyBrowser.push(`${code}/auth/login`);
    }
    throw error;
  } else {
    throw error;
  }
});
export default axiosInstance;