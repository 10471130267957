import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import TableElement from '../../../elements/table/normal-table-element';
import { createTraining, addSubject, deleteSubject } from '../../../global-state/actions/training-actions';
import { closeModal } from '../../../global-state/actions/modal-actions';
import { fetchData } from '../../../base/functions/Functions';

type TrainingState = "ADD" | "DELETE" | "AVAILABLE" | "ADDED"
interface TrainingSubjects {
    id:number,
    title: string,
    professor: string,
    type: string,
    state: TrainingState
}

interface MigrationPayload{
    action: string,
    trainingType: string,
    trainingId: number,
    idSubject: number,
    typeSubject: string
}

const AddPathTrainingModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    const types = [{ label: t("Course"), value: "COURSE" }, { label: t("parcoursTitle"), value: "PATH" }]
    const headers = [
        { title: t("courseTable"), sorted: true, classNames: "w-38", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("professeur"), sorted: true, classNames: "w-23", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("typeTable"), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t("Actions"), sorted: false, classNames: "w-10", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-5 pe-1" },
    ]
    const [availableSubjects, setAvailableSubjects] = useState<TrainingSubjects[]>([])
    const [data, setData] = useState<any>({ headers });
    const [pathSubjects, setPathSubjects] = useState<TrainingSubjects[]>([])
    let trainings = useMemo(() => {
        if (props?.training !== undefined) {
            return props?.training;
        }
    }, [props?.training])
    let path = useMemo<any>(() => {
        if (props?.path !== undefined) {
            return props?.path;
        }
    }, [props?.path])



    useEffect(() => {
        if(path?.subjectsByPath?.length!==0){
            let name = (props?.connectedUserInfo?.firstName as string).charAt(0).toUpperCase().concat(". ").concat((props?.connectedUserInfo?.lastName as string).charAt(0).toUpperCase()).concat((props?.connectedUserInfo?.lastName as string).substr(1, (props?.connectedUserInfo?.lastName as string).length));            
            props?.trainings?.forEach((training: any) => {
                let added = props?.path?.subjectsByPath?.findIndex((sub: any) => ( sub?.path?.id === training?.id) || ( sub?.course?.id === training?.id));
                let subs: TrainingSubjects[] = [];
                subs.push({id:training.id, title: training.title, professor: name, type: training.type, state: added === -1 ? "AVAILABLE" : "ADDED" })
                setPathSubjects(subs)
            })
        }else{
          setPathSubjects([])
        }
    }, [path])
    
    

    const manageSubjects = (trainingSubject: TrainingSubjects, action: TrainingState) => { 
        let subjects: TrainingSubjects[] = pathSubjects;
        if (action === "ADD") {
            let toAdd: TrainingSubjects = trainingSubject;
            toAdd.state = "ADD";
            subjects.push(toAdd);

            let index:number=availableSubjects.findIndex((item:any)=>item.id===trainingSubject.id)
            let subCopy=availableSubjects;
            subCopy[index].state="ADDED";
            setAvailableSubjects(subCopy);
        } else if (action === "DELETE") {
            let toDelete: TrainingSubjects = trainingSubject;
            toDelete.state = "DELETE";
            subjects = subjects.filter((sub: TrainingSubjects) => sub.id !== toDelete.id)

            let index:number=availableSubjects.findIndex((item:any)=>item.id===trainingSubject.id)
            let subCopy=availableSubjects;
            subCopy[index].state="AVAILABLE";
            setAvailableSubjects(subCopy);

        }
        setPathSubjects(subjects);
        formatToDisplayData()
    }

    useEffect(() => {
        if (props.trainings !== undefined) {
            let name = (props?.connectedUserInfo?.firstName as string).charAt(0).toUpperCase().concat(". ").concat((props?.connectedUserInfo?.lastName as string).charAt(0).toUpperCase()).concat((props?.connectedUserInfo?.lastName as string).substr(1, (props?.connectedUserInfo?.lastName as string).length));
            let subs: TrainingSubjects[] = [];
            props?.trainings?.forEach((training: any) => {
                let added = path?.subjectsByPath?.findIndex((sub: any) => (sub?.path?.id === training?.id) || (sub?.course?.id === training?.id));
                subs.push({id:training.id, title: training.title, professor: name, type: training.type, state: added === -1 ? "AVAILABLE" : "ADDED" })
            })
            setAvailableSubjects(subs);
        }
    }, [props?.trainings])



    
    const formatToDisplayData =()=>{
        setData({
            ...data, body: availableSubjects?.map((e: TrainingSubjects) => {
                return {
                    row: {
                        actionName: "",
                        classNames: "table-white",
                        columns: [
                            { content: e?.title, classNames: "", actionName: '' },
                            { content: e.professor, classNames: "", actionName: '' },
                            { content: types.find((type: any) => type?.value === e?.type)?.label, classNames: "", actionName: '' },
                            {
                                content: <div className="d-flex justify-content-end">
                                    {e?.state === "ADDED" &&
                                        <button onClick={()=>{ manageSubjects(e ,"DELETE")}}  type="button" className="btn-Secondary medium icon-left adding-done">
                                            <span className="material-icons-outlined">
                                                check
                                            </span>
                                            <div className="my-auto" style={{ paddingLeft: 6 }}>{t("added")}</div>
                                        </button>
                                       }
                                        {e?.state==="AVAILABLE" && <button onClick={()=>{ manageSubjects(e ,"ADD")}} type="button" className="btn-Secondary medium icon-left" style={{ width: 116 }}>
                                            <span className="material-icons-outlined">
                                                add
                                            </span>
                                            <div className="my-auto" style={{ paddingLeft: 6 }}>{t("add")}</div>
                                        </button>}
                                </div>, classNames: "text-end with-icon", actionName: ''
                            },
                        ]
                    }
                }
            }
            )
        })
    }

    useEffect(() => {
        formatToDisplayData()
    }, [availableSubjects, props.path, pathSubjects])

    
    const updateTrainingContent=async()=>{
        //format object for update + update path content
        let subjectByPathList:any[]=[]
        pathSubjects.forEach((subject:TrainingSubjects, index:number)=>{
            let subjectByPath = {
                entityType: subject.type,
                entityId: subject.id,
                index: index,
                mandatories: []
            }
            subjectByPathList.push(subjectByPath)
        })
        await props?.createTraining("paths", { ...path, subjectsByPath: subjectByPathList })
        //migrate training content if the training is published
        //must update the backend api
        if(path.status==="PUBLISHED"){
            let payloadList:MigrationPayload[] = []
            pathSubjects.forEach((toMigrate:TrainingSubjects)=>{
                payloadList.push({
                    "action": toMigrate?.state,
                    "trainingType": path?.type,
                    "trainingId": path?.id,
                    "idSubject": toMigrate?.id,
                    "typeSubject": toMigrate.type
                })
            })
            await fetchData('POST', process.env.REACT_APP_BASE_URL14 + "followUp/updateStudentFollowsAfterPublishedTrainingUpdateList",payloadList)
        }
        props?.closeModal()
    }


    return (
        <div style={{ backgroundColor: "#F8F8FA" }}>
            <div className="modal-header" style={{ paddingBottom: 0, borderBottom: "none" }}>
                <div className="w-100 d-flex align-items-center justify-content-between p-3">
                    <span className='H3-Headline black-800'>{t("Ajouter un contenu")}</span>
                    <button className="btn-QuickActions" onClick={() =>updateTrainingContent() }><span className="material-icons">close</span></button>
                </div>
            </div>
            <div className='m-3'>
                <TableElement data={data} />
            </div>
            <div className="modal-footer" style={{ paddingBottom: 0, borderTop: "none", overflow: 'hidden' }}>
            </div>
        </div>
    )
}
const mapStateToProps = (state: AppState) => ({
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    connectedUser: state.authReducer.connectedUser,
    connectedUserInfo: state.authReducer.connectedUserInfo,
    trainings: state.trainingReducer.allowedTrainings,
    path: state.trainingReducer.path,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            createTraining,
            addSubject,
            deleteSubject,
            closeModal
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddPathTrainingModalComponent);