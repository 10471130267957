import { ComponentProps, FC, useEffect, useCallback, useState } from 'react';
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { connect } from 'react-redux';
import { loginUser, registerStudent } from '../../../global-state/actions/auth-actions';
import { closeModal, closeModalWithAction, openModal } from '../../../global-state/actions/modal-actions';
import { ModalIDS } from '../modal-ids';
import axiosInstance from '../../../helpers/interceptors';
import axios from 'axios';
import { debounce } from 'lodash';
import { useHistory } from 'react-router';

interface StudentRegisterObject {
    email: string,
    lastName: string,
    firstName: string,
    password: string,
    birthDay: string,
    photo: string,
    gender: StudentGender,
    levelStudy: string,
    country: string,
    city: string,
    link: string
}
type StudentGender = "FEMALE" | "MALE"

const SignUpModalComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {
    const { t } = useTranslation();
    let [firstName, setFirstName] = useState<any>("")
    let [lastName, setLastName] = useState<any>("");
    const [gender, setGender] = useState<any>(null);
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [showP, setShowP] = useState(false);
    const [verifFirstName, setVerifFirstName] = useState<boolean>(false)
    const [verifLastName, setVerifLastName] = useState<boolean>(false)
    const [buttonLoader, setButtonLoader] = useState<boolean>(false)
    const [verifEmail, setVerifEmail] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<string>("")
    const [emailClass, setEmailClass] = useState<any>()
    const pattern = /^(([^<>()[\]\\.,;:*\s@"]+(\.[^<>()[\]\\.,;:*\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const regx = RegExp(pattern)
    const [registerBtn, setRegisterBtn] = useState<boolean>(false)
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [showP1, setShowP1] = useState(false);
    const [verifConfirmPassword, setVerifConfirmPassword] = useState<boolean>(false)
    const [passwordError, setPasswordError] = useState("")
    const [verifPassword, setverifPassword] = useState<boolean>(false)
    const [registerClicked, setRegisterClicked] = useState<boolean>(false)
    const [isNotSelectedGender, setNotSelectedGender] = useState<boolean>(false)





    const followTraining = async () => {
        await props.content.followTraining.followTrainingCheck()
    }
    useEffect(() => {
        verifyPassword()
    }, [password, confirmPassword])
    useEffect(() => {
        if (firstName.length != 0) {
            verifyFirstName(firstName)
        }
    }, [firstName])
    useEffect(() => {
        if (lastName.length != 0) {
            verifyLastName(lastName)
        }
    }, [lastName])



    const verifyPassword = () => {

        if (password?.length !== 0 && password?.length < 8) {
            setVerifConfirmPassword(true)
            setPasswordError("Votre mot de passe doit comporter au moins 8 caractères")
        } else if (password?.length !== 0 && confirmPassword?.length !== 0 && password !== confirmPassword) {
            setVerifConfirmPassword(true)
        }
        else {
            setPasswordError("")
            setverifPassword(false)
            setVerifConfirmPassword(false)
        }
    }

    const verifyFirstName = (value: string) => {
        if (value?.length === 0) {
            setVerifFirstName(true)
        } else {
            setVerifFirstName(false)
        }
    }

    const verifyLastName = (value: string) => {
        if (value?.length === 0) {
            setVerifLastName(true)
        } else {
            setVerifLastName(false)
        }
    }

    const updateMail = async (mail: string) => {
        setEmail(mail)
        await debounceVerifPasswordAction()

    }







    const showRegisterErrors = () => {
        verifyFirstName(firstName)
        verifyLastName(lastName)
        if (!regx.test(email)) {
            setVerifEmail(true)
        } else {
            setVerifEmail(false)
            verifyUserInDataBase()
        }
        if ((password.length === 0 && confirmPassword.length === 0) || (password !== confirmPassword)) {
            setVerifConfirmPassword(true)
        }
        if ((password.length === 0 && confirmPassword.length === 0)) {
            setverifPassword(true)

        }

    }



    useEffect(() => {
        if (email === '' || email.length > 0) return setEmailClass("form-control input text-default")
        if (regx.test(email)) return setEmailClass("form-control input text-default success")
    }, [email])

    const allowRegister = async () => {
        let studentAccount: StudentRegisterObject = {
            email: email?.toLowerCase(),
            lastName: lastName,
            firstName: firstName,
            password: password,
            birthDay: "",
            photo: "",
            gender: gender,
            levelStudy: "",
            country: "",
            city: "",
            link: window.location.href
        }
        let valid = await validateUser(studentAccount)
        if (valid) {
            setRegisterBtn(true)
        } else {
            setRegisterBtn(false)
        }
    }


    function isValidGender(toTest: any): toTest is StudentGender {
        return toTest === "FEMALE" || toTest === "MALE";
    }


    const validateUser = async (student: StudentRegisterObject) => {
        let verifMail = await verifyUserInDataBase();
        let verifGender = await isValidGender(student.gender);
        if (
            student.firstName &&
            student.lastName &&
            student.gender &&
            student.email &&
            confirmPassword &&
            typeof student.firstName === 'string' &&
            typeof student.lastName === 'string' &&
            typeof student.email === 'string' &&
            typeof confirmPassword === 'string' &&
            student.password === confirmPassword &&
            verifMail &&
            verifGender
        ) {
            return true;
        } else {
            return false;
        }

    }



    const continueToFollowTraining = async () => {
        await props?.closeModalWithAction("connected");
    }

    useEffect(() => {
        if (props?.connectedUser &&
            localStorage.getItem('access_token') !== null
            && localStorage.getItem('access_token') !== undefined
            && localStorage.getItem('access_token') !== '') {
            continueToFollowTraining()
        }
    }, [props?.connectedUser])


    const signUp = () => {
        props?.openModal({ idModal: ModalIDS.SIGN_IN_MODAL, size: "sm", data: {} });
    }


    useEffect(() => {

        if (email?.length === 0 && registerClicked === false) {
            setEmailClass("form-control input text-default")
            setEmailError("")
            setVerifEmail(false)
        }
    }, [email, registerClicked])



    const verifyUserInDataBase = async () => {

        if (email?.length === 0) {
            setEmailClass("form-control input text-default error")
            setEmailError("Champ obligatoire")
            setVerifEmail(true)
            setRegisterClicked(false)
            return false;
        }
        else if (email?.length !== 0 && regx.test(email)) {
            const key: any = process.env.REACT_APP_ABSTRACT_API_KEY;

            const [verifAbstract, verifMailDatabase] = await Promise.all([
                axios.get("https://emailvalidation.abstractapi.com/v1/?api_key=" + key + "&email=" + email?.toLowerCase())
                    .then((data: any) => {
                        if (data.data.deliverability === "DELIVERABLE") {
                            return true;
                        } else if (data.data.deliverability === "UNDELIVERABLE") {
                            return false;
                        }
                    }),

                axiosInstance.get(process.env.REACT_APP_BASE_URL2 + "person/getpersonbyemail/" + email?.toLowerCase())
                    .then((emailRes: any) => {
                        if (emailRes.data === "Email not found") {
                            return true
                        } else {
                            return false
                        }
                    })
            ])

            if (verifAbstract === true && verifMailDatabase === true) {
                setEmailClass("form-control input text-default")
                setVerifEmail(false)
                return true
            } else if (verifAbstract === false && verifMailDatabase === false) {
                setEmailError("Adresse e-mail invalide")
                setEmailClass("form-control input text-default error")
                setVerifEmail(true)
                return false;
            }

            else if (verifAbstract === false || verifMailDatabase === false) {
                if (verifMailDatabase === false) {
                    setEmailError("Cette adresse mail est déjà utilisée")
                    setVerifEmail(true)
                    return false;
                } else if (verifAbstract === false) {
                    setEmailError("Adresse e-mail invalide")
                    setEmailClass("form-control input text-default error")
                    setVerifEmail(true)
                    return false;
                }
            }



        }
        else if (regx.test(email) === false) {
            setEmailError("Adresse e-mail invalide")
            setVerifEmail(true)
            setEmailClass("form-control input text-default error")
        }


    }

    const debounceVerifPasswordAction = useCallback(debounce(verifyUserInDataBase, 1000), []);
    const addNewStudent = async () => {
        setRegisterClicked(true);
        setButtonLoader(true)
        !gender ? setNotSelectedGender(true) : setNotSelectedGender(false);

        let studentAccount: StudentRegisterObject = {
            email: email?.toLowerCase(),
            lastName: lastName,
            firstName: firstName,
            password: password,
            birthDay: "",
            photo: "",
            gender: gender,
            levelStudy: "",
            country: "",
            city: "",
            link: window.location.href
        }


        let valid = await verifyUserInDataBase();
        let objectValid = await validateUser(studentAccount);

        if (objectValid && valid) {
            await props?.registerStudent(studentAccount)
            const offer_token: any = localStorage.getItem("access_limitation");
            localStorage.clear();
            localStorage.setItem("access_limitation", offer_token);
            await props?.loginUser(email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""), password)
            await followTraining()

        } else {

            showRegisterErrors()
        }
        setButtonLoader(false)
    }







    return (
        < div style={{ maxWidth: "464px", overflow: 'hidden' }}>
            <div className="modal-header" style={{ border: "none" }}>
                <div className="ms-auto" style={{ paddingTop: "24px" }}>
                    <button className="btn-QuickActions" onClick={() => props?.closeModal()}>
                        <span className="material-icons-outlined" >close</span>
                    </button>
                </div>
            </div>
            <div className="modal-body  w-100" style={{ paddingTop: 0, overflow: 'hidden' }}>
                <div className="row body-modal-publish" >
                    <div className="d-flex flex-column align-items-center w-100 mb-4" >
                        <div className="H3-Headline black-800" style={{ paddingLeft: "40px" }}>Inscription </div>
                        <span className="body-md  black-800" style={{ paddingLeft: "40px" }}>Finalisez votre achat et profitez du </span>
                        <span className="body-md  black-800" style={{ paddingLeft: "40px" }}>cours en créant un compte</span>
                        <div className=" w-100" style={{ paddingTop: "22px", paddingLeft: "40px" }}>

                            <div className="d-flex flex-row w-100 align-item-center justify-content-center" style={{ gap: "26px" }}>
                                <div>
                                    <div className="d-flex align-item-center">
                                        <input dir="ltr" className="input-style-radio" type="radio" id="test1" value={gender} name="gender" onChange={() => setGender("FEMALE")} />
                                        <label htmlFor="test1" style={isNotSelectedGender ? { border: '2px solid #e06a6a' } : {}}></label>
                                        <span className={"form-title ps-1 ms-2"} style={isNotSelectedGender ? { color: '#e06a6a' } : {}}>{t('madame')}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className=" d-flex ">
                                        <input dir="ltr" className="input-style-radio" type="radio" id="test2" value={gender} name="gender" onChange={() => setGender("MALE")} />
                                        <label htmlFor="test2" style={isNotSelectedGender ? { border: '2px solid #e06a6a' } : {}}></label>
                                        <span className={"form-title ps-1 ms-2"} style={isNotSelectedGender ? { color: '#e06a6a' } : {}}>{t('monsieur')} </span>
                                    </div>
                                </div>
                            </div>

                            <div className=" w-100" style={{ paddingTop: "16px" }} >
                                <span className='body-md bold' id="first-name">Prénom</span>
                                <input
                                    dir="ltr"
                                    type="text"
                                    className={verifFirstName ? "form-control input text-default error" : "form-control input text-default"}
                                    placeholder={"Prénom"}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                {verifFirstName === true && <span style={{ color: "#E06A6A" }} className='error-input'> Champ obligatoire</span>}

                            </div>
                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold' id="last-name">Nom</label>
                                <input
                                    dir="ltr"
                                    type="text"
                                    className={verifLastName ? "form-control input text-default error" : "form-control input text-default"}
                                    placeholder={"Nom"}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                {verifLastName === true && <span style={{ color: "#E06A6A" }} className='error-input'> Champ obligatoire</span>}
                            </div>
                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold' id="email">E-mail</label>
                                <input dir="ltr" type="text" autoComplete="false" className={verifEmail ? "form-control input text-default error" : "form-control input text-default"} placeholder={"E-mail"} value={email} onChange={(event) => { updateMail(event.target.value.trim()) }} onBlur={() => verifyUserInDataBase()} />
                                {verifEmail === true && <span style={{ color: "#E06A6A" }} className='error-input'>{emailError}</span>}
                            </div>
                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold' id="mdp">Mot de passe</label>
                                <div className="input-form-control">
                                    <input autoComplete="new-password" dir="ltr" type={showP ? "text" : "password"} className={verifPassword ? "form-control input text-default error" : "form-control input text-default"} placeholder={"Mot de passe"} value={password} onChange={(event) => { setPassword(event.target.value) }} />
                                    {!showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                    {!showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility_off</span>}
                                    {showP && password !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP(!showP)}>visibility</span>}
                                </div>

                                {(verifPassword === true && passwordError.length != 0) && <span style={{ color: "#E06A6A" }} className='error-input'>{passwordError}</span>}
                                {(verifPassword === true && passwordError.length === 0) && <span style={{ color: "#E06A6A" }} className='error-input'>Champ obligatoire.</span>}
                            </div>

                            <div className="w-100" style={{ paddingTop: "16px" }}>
                                <label className='body-md bold' id="confirm-mdp">Confirmer votre mot de passe</label>
                                <div className="input-form-control">
                                    <input autoComplete="new-password" dir="ltr" type={showP1 ? "text" : "password"} className={verifConfirmPassword ? "form-control input text-default error" : "form-control input text-default"} placeholder={"Mot de passe"} value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value) }} />
                                    {!showP1 && confirmPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP1(!showP1)}>visibility_off</span>}
                                    {showP1 && confirmPassword === '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP1(!showP1)}>visibility</span>}
                                    {!showP1 && confirmPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP1(!showP1)}>visibility_off</span>}
                                    {showP1 && confirmPassword !== '' && <span className="material-icons" style={{ cursor: 'pointer', color: '#272727' }} onClick={() => setShowP1(!showP1)}>visibility</span>}

                                </div>
                                {(verifConfirmPassword === true && confirmPassword.length != 0) && <span style={{ color: "#E06A6A" }} className='error-input'>Les mots de passes ne correspondent pas.</span>}
                                {(verifConfirmPassword === true && confirmPassword.length === 0) && <span style={{ color: "#E06A6A" }} className='error-input'>Champ obligatoire.</span>}

                            </div>

                            <div className="d-flex flex-column align-items-center" style={{ paddingTop: "32px" }}>
                                {buttonLoader === false ? <button type="button" className="btn-Primary large" onClick={(event) => { event.preventDefault(); addNewStudent() }} >
                                    Valider
                                </button> :

                                    <button className="btn-Primary loaddata large">
                                        <div className="threedots-large-Animation">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </button>}

                            </div>
                            <div className="d-flex  flex-column align-items-center" style={{ paddingTop: "16px" }}>
                                <div className="text-center" >
                                    <span className="body-md bold black-800" >Vous avez déjà un compte ? </span>
                                    <button className="btn-text forgot-password" onClick={() => signUp()}>Se connecter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ div>
    )
}
const mapStateToProps = (state: AppState) => ({
    content: state.modalReducer.modalContent,
    connectedUser: state.authReducer.connectedUser,
    courseInfo: state.courseReducer.courseInfo,
    formPaymentInfo: state.courseReducer.formPaymentInfo,
    paymentSchoolInfo: state.schoolInfoReducer.paymentSchoolInfo,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
    training: state.trainingReducer.path,
    trainingSession: state.trainingReducer.session,
    userInfo: state.authReducer.connectedUserInfo,
    courseTarificationForm: state.courseReducer.courseTarificationForm,
    actualOffer: state.courseReducer.actualOffer,
    lengthCourses: state.courseReducer.LengthCoursesPublished,

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            loginUser,
            closeModal,
            openModal,
            registerStudent,
            closeModalWithAction
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(SignUpModalComponent);