import { fetchData } from "../base/functions/Functions";

export const createFeedbackApi = async (object: any) =>{
    const baseUrl = process.env.REACT_APP_BASE_URL8;
    const response = await fetchData('POST',baseUrl+"feedback/createFeedback", object)
    return response
}

export const getMyFeedbackApi = async (object: any)=>{
    const baseUrl = process.env.REACT_APP_BASE_URL8;
    const response = await fetchData("POST",baseUrl + "feedback/myFeedback/", object)
    return response
}

export const getAllFeedbacksApi = async (idSubject: number, page: number, action: string)=>{
    const baseUrl = process.env.REACT_APP_BASE_URL8;
    const baseUrlPerson= process.env.REACT_APP_BASE_URL2;
    let feedbacks = await fetchData("POST",baseUrl+"feedback/all/"+idSubject, {page}).then(res =>{return res })
    .catch(err=>{return{feedbacks:[]}})
    
    let listPersonId: any =feedbacks?.feedbacks?.filter((filter: any) => filter.userStatus=== 'USER').map((obj: any) =>{return obj.idPerson})
    const listPerson = await fetchData("POST",baseUrlPerson+"person/student", listPersonId).then(response=>{ return response})
        
        
    feedbacks['feedbacks']=feedbacks?.feedbacks?.map((obj: any) => {
        let idPer= obj.idPerson;
        const person = listPerson.find((personItem: any) => personItem.id === Number (idPer));
        
        if(person) {
        obj.idPerson= person;
        }
        return obj;
    })
    return feedbacks
}

